import React from "react";
import { Text } from "@onnit-js/ui/components/text";
import { Img } from "@onnit-js/ui/components/image";
import Button from "@onnit-js/ui/components/button/Button";
import Box from "@onnit-js/ui/components/box/Box";
import Feature from "@onnit-js/ui/components/module/Feature";
import { Story } from "../../interfaces/Story";

interface StoryProps extends Story {
    baseUrl: string;
    imgParams: string;
}

export default ({ baseUrl, headline, title, description, img, href, imgAltText, imgParams, ...rest }: StoryProps) => (
    <Feature
        {...rest}
        heading={(
            <Text as="h2" typeStyle="copyDisplay02" textTransform="uppercase" color="white">
                <Text as="a" href={href} typeStyle="copyDisplay02" textDecoration="none">
                    {`"${headline}"`}
                </Text>
            </Text>
        )}
        href={href}
        image={<Img alt={imgAltText} src={`${baseUrl}/${img}${imgParams}`} width={2608} height={1448} />}
        content={(
            <Box px={[4, 4, 0]}>
                <Text as="h3" typeStyle="body" color="white" textTransform="uppercase" fontWeight="bold" pb={1}>
                    {title}
                </Text>
                <Text as="p" typeStyle="body" color="white" dangerouslySetInnerHTML={{ __html: description }} />
            </Box>
        )}
        cta={(
            <Button el="a" href={href} width={1} aria-label={`read more about ${headline}.`}>
                Full Story
            </Button>
        )}
    />
);
