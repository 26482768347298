import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import theme from "@onnit-js/ui/components/themes/light";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Text, H1, } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import { Img } from "@onnit-js/ui/components/image";
import Hero from "@onnit-js/ui/components/module/Hero";
import { TextProps } from "@onnit-js/ui/components/text/Text";
import config from "../../config/config";
import WorkableEmbed from "../../components/common/WorkableEmbed";
import useScrollToRef from "../../hooks/useScrollToRef";
import { Accordion, AccordionItem } from "../../components/common/Accordion";
import { Features } from "../stories/StoryView";
import { StoryCategory } from "../../enums/Story";

interface SectionHeadingProps extends TextProps {
    heading: string;
    subheading?: string;
}

const SectionHeading = ({ heading, subheading, color }: SectionHeadingProps) => (
    <Box mb={[4, 5]}>
        <Text as="h2" textTransform="uppercase" fontWeight="black" fontSize={[3, 5]} color={color}>
            {heading}
        </Text>
        {subheading && (
            <Text
                as="h3"
                textTransform="uppercase"
                fontWeight="bold"
                fontStyle="italic"
                fontSize={[1, 2]}
                color="grays.4"
            >
                {subheading}
            </Text>
        )}
    </Box>
);

interface CTABarProps extends BoxProps {
    scrollToFn: any;
    reverse?: boolean;
    text: string;
}

const CTABar: React.FC<React.PropsWithChildren<React.PropsWithChildren<CTABarProps>>> = (
    {
        scrollToFn,
        reverse = false,
        text,
        ...rest
    }) => (
        <Box
            bg="sunwashGold"
            py={[6, 6, 6, 4]}
            display="flex"
            flexDirection={["column-reverse", "column-reverse", "column-reverse", reverse ? "row-reverse" : "row"]}
            alignItems="center"
            justifyContent="center"
            {...rest}
        >
            <Box mx={4} my={2}>
                <Button size="medium" onClick={scrollToFn}>
                    See Available Positions
                </Button>
            </Box>
            <Box mx={4} my={2}>
                <Text
                    as="p"
                    textTransform="uppercase"
                    fontStyle="italic"
                    fontWeight="black"
                    fontSize={[3, 4, 3, 4]}
                    textAlign="center"
                >
                    {text}
                </Text>
            </Box>
        </Box>
);

export default function Page() {
    const onnitContext = useOnnitContext();
    const [jobsRef, scrollToJobs] = useScrollToRef();

    if (!onnitContext) {
        return null;
    }

    const IMG_URL = `${onnitContext.url.cdn.static_images}/landers/careers`;
    const { IMG_PARAMS } = config;

    return (
        <ThemeProvider theme={theme}>
            <H1 className="sr-only">Careers</H1>
            <Box maxWidth="1920px" mx="auto">
                <Hero
                    mb={[2, 4, 4, 0]}
                    image={(
                        <Img
                            lazyLoad={false}
                            src={`${IMG_URL}/CareersPage-0.jpg${IMG_PARAMS}`}
                            alt="Man resting from exercising"
                            width={2910}
                            height={1340}
                        />
                    )}
                >
                    <Box px={4} py={[2, 2, 4]}>
                        <SectionHeading heading="About Onnit" />
                        <Text as="p" typeStyle="body">
                            Our mission is to support our community and employees in reaching their ultimate potential,
                            mentally, physically, and spiritually—a process we call Total Human Optimization.
                            <br />
                            <br />
                            If you’re passionate about self-improvement, join a growing community that believes becoming
                            their best selves can better the world at large.
                        </Text>
                    </Box>
                </Hero>

                <Box display={["block", "block", "block", "grid"]} gridTemplateColumns="repeat(12, 1fr)" mb={[6, 6, 8]}>
                    <CTABar
                        scrollToFn={scrollToJobs}
                        text="Become the best version of yourself — for the good of all."
                        gridColumn="1 / -1"
                    />

                    <Box gridColumn="2 / span 10" mt={[4, 4, 6]} mb={[0, 0, 0, 6]} px={4} py={[6, 6, 8]}>
                        <Img
                            src={`${IMG_URL}/CareersPage-Image-2.jpg${IMG_PARAMS}`}
                            alt="Collage of a man holding a kettlebell, woman running and man wearing onnit apparel"
                            width={2320}
                            height={1072}
                        />
                    </Box>

                    <Box gridColumn={["2 / span 5", "2 / span 5", "2 / span 4", "2 / span 5", "3 / span 4"]} px={4}>
                        <SectionHeading heading="Who we are" subheading="A team of relentless optimizers" />
                        <Text as="p" typeStyle="body" mb={6}>
                            Based in Austin, TX, and powered by Unilever, Onnit is made up of athletes and
                            intellectuals, artists and entrepreneurs, parents and lifelong students, and more. Some of
                            them can juggle a kettlebell, and others have never even lifted one—but everybody shares the
                            goal of getting one-percent better every day.
                            <br />
                            <br />
                            At Onnit, you will find kindred spirits to travel with you on the path to a better body and
                            mind, and a more complete life.
                        </Text>
                    </Box>

                    <Box gridColumn="8 / span 4" px={4} mb={[0, 0, 0, 4]}>
                        <SectionHeading heading="Our core values" subheading="This is how we stay onnit:" />
                        <Accordion>
                            <AccordionItem title="Accessibility" fontSize={[2, 3, 4]}>
                                <Text typeStyle="body">
                                    We strive to offer something for everybody, and meet people where they’re at. From
                                    the elite athlete who wants to maximize performance to the single parent trying to
                                    set an example for their kids, everyone can get Onnit.
                                </Text>
                            </AccordionItem>

                            <AccordionItem title="Authenticity" fontSize={[2, 3, 4]}>
                                <Text typeStyle="body">
                                    What you see is what we are. Our content and marketing features actual Onnit
                                    employees and fans. We are our own customers.
                                </Text>
                            </AccordionItem>

                            <AccordionItem title="Curiosity" fontSize={[2, 3, 4]}>
                                <Text typeStyle="body">
                                    We’re as hungry for knowledge as the community we serve, so we stay on top of all
                                    the emerging research in health and wellness. We’re constantly re-evaluating our
                                    product formulations as new information comes to light to see where we can improve.
                                </Text>
                            </AccordionItem>

                            <AccordionItem title="Reciprocity" fontSize={[2, 3, 4]}>
                                <Text typeStyle="body">
                                    The more you give, the more you get. Whether we’re offering free content and
                                    services, or producing sustainable products that honor the planet and treat animals
                                    humanely, we believe that good business practices will build a following loyal
                                    enough that the business end of Onnit takes care of itself.
                                </Text>
                            </AccordionItem>

                            <AccordionItem title="Transparency" fontSize={[2, 3, 4]}>
                                <Text typeStyle="body">
                                    We stake our reputation on the fact that our products are what we say they are and
                                    work like we say they will, and we base our future on the faith our community has in
                                    the brand and its management. To that end, we keep customers and employees informed.
                                    When we make mistakes, we own them, because trust is sacred, and the truth always
                                    comes out in the end.
                                </Text>
                            </AccordionItem>

                            <AccordionItem title="Quality" fontSize={[2, 3, 4]}>
                                <Text typeStyle="body">
                                    Onnit strives to go above and beyond the industry standard to source the
                                    highest-quality ingredients and materials. That means no banned substances in our
                                    sports performance products, and a no-slip grip on our steel maces and clubs. We
                                    invest in third-party testing to give customers assurance of quality and purity, and
                                    we’re proud of an ever-growing list of products that are certified safe and
                                    effective.
                                </Text>
                            </AccordionItem>
                        </Accordion>
                    </Box>

                    <Box gridColumn="2 / span 10" py={[0, 6]} px={4}>
                        <Box mt={[6, 0]} py={[4, 6]}>
                            <Img
                                src={`${IMG_URL}/CareersPage-Image-3.jpg${IMG_PARAMS}`}
                                alt="Collage of people exercising"
                                width={1648}
                                height={456}
                            />
                        </Box>
                    </Box>

                    <Box gridColumn={["2 / span 9", "2 / span 9", "2 / span 9", "2 / span 9", "3 / span 6"]} p={4}>
                        <SectionHeading heading="Benefits & Perks" />
                        <Text as="p" typeStyle="body" mb={6}>
                            At Onnit, we believe that a thriving team is the key to our success. That’s why we’re
                            committed to providing a supportive and rewarding work environment that fosters personal
                            growth, well-being, and a healthy work-life balance. Here’s what you can look forward
                            to as part of the Onnit team:
                        </Text>

                        <Accordion>
                            <AccordionItem title="Comprehensive Health Coverage" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">We care about your well-being. Our health benefits include top-tier medical, dental,
                                    and vision plans to ensure you and your family are covered.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Mental Health and Wellness Support" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    Your mental health is just as important as your physical health. We provide access to
                                    mental health resources, including counseling services and wellness programs, to
                                    support your overall well-being.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Generous Paid Time Off" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    We understand the importance of rest and rejuvenation. Onnit offers generous
                                    paid time off (PTO) for vacations, personal days, parental leave, and sick leave,
                                    plus additional holidays so you can recharge and come back refreshed.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Fitness and Wellness Perks" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    At Onnit, we practice what we preach. Enjoy free access to our state-of-the-art
                                    fitness facilities, wellness classes, and a range of Onnit products to help you
                                    achieve your peak performance. Plus, we offer discounts on all Onnit products to
                                    keep you on top of your game.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Professional Development" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    We believe in investing in our team’s future. Take advantage of our professional
                                    development opportunities, including educational courses, and annual tuition
                                    reimbursement allowance to sharpen your skills and advance your career.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Retirement Savings Plan" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    Plan for your future with confidence. Onnit offers a competitive 401(k) plan with e
                                    mployer-matching contributions to help you build a secure financial future.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Flexible Work Arrangements" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    We understand the need for balance. Whether you prefer working from home or in the
                                    office, we offer flexible work arrangements to fit your lifestyle.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Employee Recognition and Rewards" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    Hard work doesn’t go unnoticed at Onnit. We celebrate our team's achievements with
                                    regular recognition programs, performance bonuses, and opportunities for career advancement.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Team Events and Social Gatherings" fontSize={[2, 3, 4]}>
                                <Text as="p" typeStyle="body">
                                    We value community and camaraderie. From team-building activities to social events,
                                    we provide plenty of opportunities to connect, collaborate, and have fun with your colleagues.
                                </Text>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Box>

                <Box
                    display={["block", "block", "block", "grid"]}
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridGap={4}
                    mb={[0, 0, 0, 9]}
                >
                    <Box
                        gridColumn="9 / span 3"
                        gridRow="1"
                        bg="black"
                        px={[4, 5, 6]}
                        pt={[7, 7, 7, 0]}
                        display="flex"
                        alignItems="center"
                    >
                        <Text as="h3" color="sunwashGold" fontWeight="heavy" textTransform="uppercase" fontSize={[6]}>
                            Our <br />
                            commitment
                            <br /> to fairness
                        </Text>
                    </Box>
                    <Box gridColumn="2 / span 7" gridRow="1">
                        <Box bg="black" px={[4, 5, 6]} pb={7} pt={[6, 6, 6, 7]}>
                            <Text as="p" color="sunwashGold" fontWeight="heavy" fontSize={[3, 4, 5]}>
                                We want the most talented people in the world to work here, and to show up as their most
                                authentic selves without fear of discrimination or exclusion. Onnit is a place where
                                everyone can feel comfortable, regardless of age, ethnicity, race, gender, sexual
                                orientation, family status, religion, and physical ability.
                                <br />
                                <br />
                                It’s not just good for business; it’s good for everybody.
                            </Text>
                        </Box>
                    </Box>
                </Box>

                <CTABar scrollToFn={scrollToJobs} text="Are You Ready To Get Onnit?" reverse />

                <Box bg="black" mb={[0, 0, 0, 6]} py={[6, 6, 8]}>
                    <Box display={["block", "block", "grid"]} gridTemplateColumns="repeat(12, 1fr)">
                        <Box
                            gridColumn={["1 / span 2", "1 / span 2", "1 / span 2", "4 / span 2"]}
                            p={4}
                            display="flex"
                            mt={[6, 6, 0]}
                        >
                            <Box width={1} maxWidth="200px" m="auto">
                                <svg viewBox="0 0 180 180">
                                    <path
                                        fill="#FFF"
                                        d="M177.96,89.96c0-48.49-39.31-87.81-87.81-87.81c-48.49,0-87.81,39.31-87.81,87.81s39.31,87.81,87.81,87.81
 C138.65,177.77,177.96,138.46,177.96,89.96"
                                    />
                                    <path
                                        fill="#000"
                                        d="M86.42,107.03c-0.74,1.17-2.33,3.38-5.34,3.38h-3.56c-1.35,0-2.95-1.53-4.05-3.44L59.91,83.52
 c-0.18-0.31-0.55-0.31-0.73,0l-13.63,23.51c-1.72,2.82-4.36,3.38-5.71,3.38h-4.85c-1.9-0.06-2.64-1.72-1.66-3.38l19.7-34.13
 c0.55-1.04,2.52-3.38,5.65-3.38h3.44c1.59,0,2.76,1.35,3.93,3.38l13.5,23.45c0.12,0.24,0.43,0.49,0.74,0L93.85,72.9
 c0.61-1.04,2.52-3.38,5.65-3.38h3.44c1.59,0,2.76,1.35,3.93,3.38l13.5,23.45c0.12,0.24,0.43,0.49,0.74,0l13.63-23.45
 c1.72-2.82,4.24-3.38,5.59-3.38h4.85c1.9,0.06,2.82,1.72,1.84,3.38l-19.77,34.13c-0.73,1.17-2.33,3.38-5.34,3.38h-3.56
 c-1.35,0-2.95-1.53-4.05-3.44l-13.57-23.45c-0.18-0.31-0.55-0.31-0.73,0L86.42,107.03z"
                                    />
                                </svg>
                            </Box>
                        </Box>
                        <Box
                            gridColumn={["3 / -1", "3 / -1", "3 / -1", "6 / span 5"]}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            p={4}
                            textAlign={["center", "center", "left"]}
                        >
                            <SectionHeading heading="Employee Onnit Stories" color="white" />
                            <Text typeStyle="body" color="white">
                                Onnit Stories is a series of live video interviews with people who have made inspiring
                                life changes since discovering Onnit. Many of the subjects are Onnit employees. So don’t
                                take our word that Onnit is a great place to work—take theirs.
                            </Text>
                        </Box>
                    </Box>
                    <Features
                        baseUrl={`${onnitContext.url.cdn.static_images}/landers/onnit-stories/`}
                        imgParams={IMG_PARAMS}
                        initialFilters={[{ name: StoryCategory.Employee, isActive: true }]}
                        showFilterButtons={false}
                        minColumnWidth="432px"
                    />
                </Box>

                <Box ref={jobsRef} px={4} py={[6, 6, 8]} maxWidth="1024px" mx="auto">
                    <SectionHeading heading="Available Positions" />
                    <WorkableEmbed mb={4} />
                </Box>

                <Box
                    bg="black"
                    display={["block", "block", "block", "grid"]}
                    gridTemplateColumns="repeat(12, 1fr)"
                    py={[6, 6, 8]}
                >
                    <Box gridColumn={["2 / span 9", "2 / span 9", "2 / span 9", "2 / span 9", "3 / span 8"]} p={4}>
                        <SectionHeading heading="FAQ" color="white" />
                    </Box>

                    <Box gridColumn={["2 / span 9", "2 / span 9", "2 / span 9", "2 / span 9", "3 / span 8"]} px={4}>
                        <Accordion>
                            <AccordionItem
                                title="What is required for my application to be complete?"
                                fontSize={[2, 3, 4]}
                                color="white"
                            >
                                <Text typeStyle="body" color="white">
                                    Once you have reached the application page you will find all necessary fields to be
                                    completed, including contact information, uploading your resume, and a spot for your
                                    cover letter. We really value cover letters, so don’t be shy and tell us about
                                    yourself!
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="What can I expect after I apply?" fontSize={[2, 3, 4]} color="white">
                                <Text typeStyle="body" color="white">
                                    Shortly after your application has been successfully submitted, you can expect a
                                    confirmation email. From there, all ongoing communication will be sent to the email
                                    address provided in the application form - triple check that what was entered is
                                    correct!
                                    <br />
                                    <br />
                                    If you haven’t received that email within 24 hours, please check your spam/junk
                                    folder in case it skipped your inbox.
                                </Text>
                            </AccordionItem>
                            <AccordionItem
                                title="Is there someone I can contact for updates on my application?"
                                fontSize={[2, 3, 4]}
                                color="white"
                            >
                                <Text typeStyle="body" color="white">
                                    Any questions can be sent to the hiring team though that confirmation email
                                    mentioned above by replying to it.
                                    <br />
                                    <br />
                                    Please note, however, that we are unable to provide status updates for your
                                    application once it’s been submitted. There are a lot of moving parts in the hiring
                                    process and we will be in touch as soon as any decisions have been made or there are
                                    changes in your application’s status.
                                </Text>
                            </AccordionItem>
                            <AccordionItem
                                title="What is the hiring process timeline?"
                                fontSize={[2, 3, 4]}
                                color="white"
                            >
                                <Text typeStyle="body" color="white">
                                    This can vary. As you can imagine, finding the perfect candidate can take time! We
                                    kindly ask for patience and our hiring team will be in touch with all updates.
                                </Text>
                            </AccordionItem>
                            <AccordionItem title="Are internships available?" fontSize={[2, 3, 4]} color="white">
                                <Text typeStyle="body" color="white">
                                    While they are not commonly available, internship opportunities will be listed on
                                    our Careers page with all of the internship details.
                                </Text>
                            </AccordionItem>
                            <AccordionItem
                                title="Do I have to live in Austin to be considered for a position at Onnit?"
                                fontSize={[2, 3, 4]}
                                color="white"
                            >
                                <Text typeStyle="body" color="white">
                                    While there is some benefit to being in Austin (those who know, know) and near to
                                    our headquarters, an Austin address isn’t required for all positions (though some
                                    roles will require the need to be on site). That said, all employees are required to
                                    be a resident of Texas. Please keep that in mind when applying.
                                </Text>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
